<template>
	<div class="home">
		<v-card>
			<v-card-title
				class="indigo white--text"
			>
				<h3>Produtos</h3>
			</v-card-title>

			<v-card-text class="pt-2" >
				<v-expansion-panels accordion>
					<v-expansion-panel
						v-for="(item,i) in cardapio"
						:key="i"
						class="my-1"
					>
						<v-expansion-panel-header>
							{{item[0].descricaoGrupo}}
						</v-expansion-panel-header>

						<v-expansion-panel-content>
							<v-list three-line>
								<v-divider></v-divider>
								<template v-for="(p, index) in item">
									<v-list-item
										:key="index"
										color="#000"
										:to="{name: 'Produto', params: {id: p.id}}"
									>
										<v-list-item-avatar
											:rounded="false"
											tile
											height="75px"
											width="75px"
										>
											<v-img :src="'https://cdn.pixabay.com/photo/2020/06/24/19/12/cabbage-5337431_1280.jpg'"></v-img>
										</v-list-item-avatar>

										<v-list-item-content>
											<v-list-item-title v-text="p.descricao"></v-list-item-title>
											<v-list-item-subtitle
												class="text-right"
											>
												<span v-text="'R$ ' + p.valor" ></span>
											</v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>

									<v-divider :key="'div-' + index" ></v-divider>
								</template>
							</v-list>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-card-text>
		</v-card>

		<v-overlay :value="overlay">
			<v-progress-circular
				indeterminate
				size="64"
			></v-progress-circular>
		</v-overlay>
	</div>
</template>

<script>

export default {
	name: 'vwHome',
	data(){
		return {
			data: [],
			cardapio: {},
			overlay: false
		}
	},
	methods: {
		buscarCardapio: function(){
			this.overlay = true
			this.$store.dispatch('CARDAPIO', null)
				.then(response => ( response.json() ) )
				.then(resJSON => {
					let data = resJSON.response
					
					let grupo = null
					let nomeGrupo = null

					data.forEach(element => {
						if(grupo == element.idGrupo){
							this.cardapio[nomeGrupo].push(element)
						}else{
							grupo = element.idGrupo
							nomeGrupo = element.descricaoGrupo

							this.cardapio[nomeGrupo] = []
							this.cardapio[nomeGrupo].push(element)
						}
					});
				})
				.catch(error => {
					console.error('Error', '=>', error)
				})
				.finally(() => {
					this.overlay = false
				})
		}
	},
	mounted(){
		setTimeout(() => {
			this.overlay = true
			this.buscarCardapio()
		}, 1000)
	}
}
</script>

<style scoped>
	.v-list-item:nth-of-type(odd){
		background: rgba(97, 96, 96, 0.2) !important;
	}
</style>
